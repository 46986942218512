import React from "react"
import { ReportHeaderSection, ReportHeroSection, ReportAssessment, CTAFreeReportSection } from "@/layout";

const FreeReportPage: React.FC = () => {
    return (
        <div className={'container-report'}>
            <ReportHeroSection/>
            <ReportHeaderSection/>
            <ReportAssessment/>
            <CTAFreeReportSection/>
        </div>
    )
}

export default FreeReportPage
